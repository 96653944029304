<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>

      <div class="col-auto">
        <button
          @click="$router.push({ name: 'items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer un nouveau produit
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Catégorie</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ item.reference }}
          </td>
          <td>
            {{ item.fullName }}
          </td>

          <td>
            <span v-if="item.section">
              {{ item.section.fullName }}
            </span>
          </td>

          <td class="d-flex">
            <button
              @click="
                $router.push({
                  name: 'items-edit',
                  params: {
                    reference: item.reference,
                  },
                })
              "
              class="btn"
            >
              <i class="bi bi-pencil-square"></i>
            </button>
            <button @click="remove(item)" class="btn">
              <i class="bi bi-trash text-danger"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getItems",
    }),
  },
  mounted() {
    this.$store.dispatch("item/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("item/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("item/getAll");
    },

    async sort(value) {
      return await this.$store.commit("item/sort", value);
    },

    async remove(data){
      let item = data;
      await this.$confirm({
        message: "Vous voulez supprimer l'article  " + item.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("item/destroy", item.reference);
          }
        },
      });
    }
  },
};
</script>
